import { createSlice } from '@reduxjs/toolkit';
import { constants, ebBrandData } from '../facturacion/constants';

const initialState = {
    brand: constants.EB_KEY,
    data: ebBrandData
}

export const brandingSlice = createSlice ({
    name: 'branding',
    initialState,
    reducers: {
        setBrand: (state, action) => {
            state.brand = action.payload
        },
        setData: (state, action) => {
            state.data = action.payload
        } 
    }
})

export const { setBrand } = brandingSlice.actions;
export const { setData } = brandingSlice.actions;

export const selectBrand = (state) => state.branding.brand;
export const selectBrandData = (state) => state.branding.data;

export default brandingSlice.reducer;