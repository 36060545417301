import React from 'react';

export function ModalConfirmar({ 
  titulo='Confirmación...', descripcion='¿Estás seguro de realizar la acción?', mensajeImportante,
  showModal, handleConfirm 
}) {
  const handleAceptar = () => {
    handleConfirm();
    showModal()
  }
  return (
    <div className="modal steps">
      <div className="head">
        <h4>{titulo}</h4>
        <span onClick={showModal}>
          <img src={`${process.env.PUBLIC_URL}/assets/close.svg`} alt="close" />
        </span>
      </div>
      <div className="content">
        <div className="step">
          <p>{descripcion}</p>
        </div>
        <div className='mt-3 datos-fiscales'>
          <div className="actions">
            <button onClick={handleAceptar} className="primary">Aceptar</button>
            <button onClick={() => showModal()} className="secundary">Cancelar</button>
          </div>
        </div>
        {mensajeImportante &&
        <div className="mt-3 important">
          <h3>IMPORTANTE</h3>
          <div className="info">
            <h5>{mensajeImportante}</h5>
          </div>
        </div>
        }
      </div>
    </div>
  )
}