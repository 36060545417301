
export const Persona = ({ titulo, rfc, nombre, domicilio, regimenFiscal, usoCFDI }) => {
    return <>
        <h3>{titulo}</h3>
        <br />
        <PersonaItem
            titulo={"RFC"}
            valor={rfc}
        />
        <PersonaItem
            titulo={"Razón social"}
            valor={nombre}
        />
        <PersonaItem
            titulo={"Domicilio Fiscal"}
            valor={domicilio}
        />
        <PersonaItem
            titulo={"Regimen Fiscal"}
            valor={regimenFiscal}
        />
        {usoCFDI
            ? <PersonaItem
                titulo={"Uso del CFDI"}
                valor={usoCFDI}
            />
            : <></>
        }
    </>
}

const PersonaItem = ({ titulo, valor }) => {
    return <div className="one-col">
        <div className="dato">
            <p>
                <b>{titulo}: </b>
                {valor}
            </p>
        </div>
    </div>
}