import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectBrand } from '../../reducers/brandingSlice';
import { stepsState, fetchFactura, setFactura } from '../../reducers/stepsSlice';
import { constants } from '../constants';
import CardCarousel from '../../Slider';
import { Persona } from './datosFiscales/persona';

export const valorExento = "exento";
export function DatosFiscales(props) {
  /**
   * Brand/Theming 
   */
  const brand = useSelector(selectBrand);
  const { showModalConfirmar, setMensajes, setHandleConfirmar } = props;

  /**
   * Step Handle
   */
  const dispatch = useDispatch();
  const stepsData = useSelector(stepsState);
  /**
   * Change the view for the selected invoice
   */
  const changeFacturaSelected = () => {
    const previews = document.getElementsByClassName('preview');
    const previewsArray = Array.from(previews);
    previewsArray.forEach(preview => {
      preview.classList.toggle('active');
    })
  }

  /**
   * Fomat the date 
   * @param date
   */
  const formatDate = (date) => {
    const dateObj = new Date(date);

    function join(date, opt, separator) {
      function format(m) {
        let dateF = new Intl.DateTimeFormat('en', m);
        return dateF.format(date);
      }
      return opt.map(format).join(separator);
    }

    let options = [{ year: 'numeric' }, { month: '2-digit' }, { day: '2-digit' }];
    let dateFormatted = join(dateObj, options, '-');
    return dateFormatted;
  }

  /**
   * Creates the invoice by calling to the service and returning the created invoice
   * If not we show an error
   */
  const facturar = () => {
    // props.handleStep('generar');
    // return;
    dispatch(fetchFactura(stepsData.facturacionBody)).then( async (resp) => {
      if(resp.payload.data && Array.isArray(resp.payload.data.FacturasTimbradas) && resp.payload.data.FacturasTimbradas.length > 0){
        //Se verifica si hay error en el timbrado
        let errorTimbrado = false;
        let error = ''
        let errorDetalle = '';
        let isErrorDiff = false;
        resp.payload.data.FacturasTimbradas.forEach(function(factura,index){
          if (factura.Error) {
            if (!error) {
              error = `${factura.Error}`
              errorDetalle = `${factura.DetalleError}`
            } else {
              if ((error !== factura.Error || errorDetalle !== factura.DetalleError)) {
                isErrorDiff = true; 
                error = '';
                errorDetalle = '';
              }
            }
            errorTimbrado = true;
          }
        });
        if (errorTimbrado) {
          const errorGeneral = (!isErrorDiff && error) ? `${errorDetalle && errorDetalle}` : 'Sucedió un error al timbrar la factura, por favor intenta más tarde.';
          props.showErrorModal('error', errorGeneral);
        } else {
          dispatch(setFactura(resp.payload.data.FacturasTimbradas))
          props.handleStep('generar');
        }
      }else{
        props.showErrorModal('error',resp.payload.error.DetalleError);
      }
    });
  }
  const handleShowModalConfirm = () => {
    const titulo = 'Facturar';
    const descripcion = '¿Desea confirmar la emisión de la factura?';
    const importante = 'Por favor valida que tus datos fiscales sean los mismos que los de tu cédula fiscal. Una vez timbrado el comprobante, no podrá haber corrección de datos.';
    setMensajes(titulo, descripcion, importante);
    setHandleConfirmar(facturar);
    showModalConfirmar();

  }
  return (
    <>
      <section className="datos-fiscales">
        <div className="wrapper">
          <div className="data">
            <p className="text-secondary"><b>Revisa tus datos para realizar tu factura final</b></p>
            <div className="preview-wrapper">
              <CardCarousel data={
                stepsData.preFactura
                  ? stepsData.preFactura.data.map((factura, index) => {
                    const isUnica = stepsData.preFactura.data.length === 1 ? true : false;
                    const { Emisor, Receptor, Fecha, TotalEnLetras, MetodoPago, FormaPago, DescFormaPago, Moneda, SubTotal, TotalImpuestosTrasladados, Total } = factura.Ingreso;
                    let isExento = true;
                    for (let t of factura.Ingreso.Conceptos) {
                      if (t.ImpuestosTrasladados[0].TipoFactor.toLowerCase() !== valorExento) {
                        isExento = false;
                        break;
                      }
                    }
                    const subtotal = (SubTotal.toString().search('.') !== -1 ? SubTotal.toFixed(2) : `${SubTotal}.00`) + '';
                    const iva = isExento ? "" : (TotalImpuestosTrasladados.toString().search('.') !== -1 
                      ? TotalImpuestosTrasladados.toFixed(2) 
                      : `${TotalImpuestosTrasladados}.00`
                    ) + '';
                    const total = (Total.toString().search('.') !== -1 ? Total.toFixed(2) : `${Total}.00`) + '';
                    return <div 
                      key={index} 
                      className={((brand === constants.EB_KEY && Emisor.Rfc === constants.ER_IDENTIFIER) || isUnica) 
                        ? 'preview' : 'preview'
                      }
                    >
                      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <img 
                          src={`${process.env.PUBLIC_URL}/assets/marcas/${Emisor.Rfc.toUpperCase()}.jpg`} 
                          alt={brand}
                          onError={event => {
                            event.target.src = `${process.env.PUBLIC_URL}/assets/marcas/AEB611030SN7.jpg`;
                            event.onerror = null;
                          }}
                          style={{ width: '250px' }} 
                        />
                        <div className="datos-generales">
                          <h2>Factura</h2>
                          <div className="dato">
                            <p><b>Fecha de emisión:</b></p>
                            <p>{formatDate(Fecha)}</p>
                          </div>
                        </div>
                      </div>
                      <br />
                      <br />
                      <div className="resumen-wrapper">
                        <div className="resumen-wrapper-info">
                          <Persona 
                            titulo={"Emisor"}
                            rfc={factura.Ingreso.Emisor.Rfc}
                            nombre={factura.Ingreso.Emisor.Nombre}
                            domicilio={`${Emisor.Calle ? `${Emisor.Calle} ` : ""}${Emisor.NumeroExterior ? `${Emisor.NumeroExterior} ` : ""}${Emisor.NumeroInterior ? `Int ${Emisor.NumeroInterior} ` : ""}${Emisor.Colonia ? `${Emisor.Colonia} ` : ""}C.P. ${Emisor.CodigoPostal}${Emisor.Municipio ? `, ${Emisor.Municipio}` : ""}${Emisor.Entidad ? `, ${Emisor.Entidad}` : ""}${Emisor.Pais ? `, ${Emisor.Pais}` : ""}`}
                            regimenFiscal={Emisor.DescRegimenFiscal ? `${Emisor.RegimenFiscal} - ${Emisor.DescRegimenFiscal}` : Emisor.RegimenFiscal}
                          />
                          <br />
                          <Persona 
                            titulo={"Receptor"}
                            rfc={Receptor.Rfc}
                            nombre={Receptor.Nombre}
                            domicilio={`${Receptor.Calle ? `${Receptor.Calle} ` : ""}${Receptor.NumeroExterior ? `${Receptor.NumeroExterior} ` : ""}${Receptor.NumeroInterior ? `Int ${Receptor.NumeroInterior} ` : ""}${Receptor.Colonia ? `${Receptor.Colonia} ` : ""}C.P. ${Receptor.CodigoPostal}${Receptor.Municipio ? `, ${Receptor.Municipio}` : ""}${Receptor.Entidad ? `, ${Receptor.Entidad}` : ""}${Receptor.Pais ? `, ${Receptor.Pais}` : ""}`}
                            regimenFiscal={Receptor.DescRegimenFiscal ? `${Receptor.RegimenFiscal} - ${Receptor.DescRegimenFiscal}` : Receptor.RegimenFiscal}
                            usoCFDI={Receptor.DescUsoCFDI ? `${Receptor.UsoCFDI} - ${Receptor.DescUsoCFDI}` : Receptor.UsoCFDI}
                          />
                        </div>
                      </div>
                      <div className="resumen-wrapper">
                        <div className="resumen">
                          <table width="100%">
                            <thead>
                              <tr>
                                <th>Cantidad</th>
                                <th>Unidad</th>
                                <th>Concepto</th>
                                <th>Precio Unitario</th>
                                <th>IVA %</th>
                                <th>Monto IVA</th>
                                <th>Importe</th>
                              </tr>
                            </thead>
                            <tbody>
                              {factura.Ingreso.Conceptos.map((ticket, index) => {
                                return <tr key={index}>
                                  <td>{ticket.Cantidad}</td>
                                  <td>{ticket.Unidad}</td>
                                  <td>{ticket.Descripcion}</td>
                                  <td>{Number(ticket.ValorUnitario).toFixed(2)}</td>
                                  <td>
                                    {ticket.ImpuestosTrasladados[0].TipoFactor.toLowerCase() !== valorExento
                                      ? ticket.ImpuestosTrasladados[0].TasaOCuota
                                      : ""
                                    }
                                  </td>
                                  <td>
                                    {ticket.ImpuestosTrasladados[0].TipoFactor.toLowerCase() !== valorExento
                                      ? Number(ticket.ImpuestosTrasladados[0].Importe).toFixed(2)
                                      : ""
                                    }
                                  </td>
                                  <td>{Number(ticket.Importe).toFixed(2)}</td>
                                </tr>
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="datos-fiscales">
                        <div className="two-col-3-1">
                          <div>
                            <div className="two-col-5-11">
                              <div className="dato"><p><b>Importe letra:</b></p></div>
                              <div className="dato">{TotalEnLetras}</div>
                            </div>
                            <div className="two-col-5-11">
                              <div className="dato"><p><b>Método de pago:</b></p></div>
                              <div className="dato">{MetodoPago}</div>
                            </div>
                            <div className="two-col-5-11">
                              <div className="dato"><p><b>Forma de pago:</b></p></div>
                              <div className="dato">{FormaPago} - {DescFormaPago}</div>
                            </div>
                          </div>
                          <div>
                            <div className="two-col-2-2">
                              <div className="dato"><p><b>Subtotal:</b></p></div>
                              <div className="dato">{subtotal}</div>
                            </div>
                            <div className="two-col-2-2">
                              <div className="dato"><p><b>IVA:</b></p></div>
                              <div className="dato">{iva}</div>
                            </div>
                            <div className="two-col-2-2">
                              <div className="dato"><p><b>Total:</b></p></div>
                              <div className="dato">{total}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  })
                  : <></>
              }
              />
            </div>
            <div style={{ padding: '5px 30px', display: 'flex', justifyContent: 'center' }} >
              <span style={{ textAlign: 'center' }} className="text-secondary">*Nota: Una vez generada la factura, no habrá corrección de domicilio o razón social.</span>
            </div>
            <br />
            <div className="actions">
              <button onClick={handleShowModalConfirm} className="primary">Facturar</button>
              <button onClick={() => props.handleStep('ingresaDatos')} className="secundary">Modificar datos</button>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}