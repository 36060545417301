import { configureStore } from '@reduxjs/toolkit';
import brandingReducer from '../reducers/brandingSlice';
import stepReducer from '../reducers/stepsSlice';

export const store = configureStore({
  reducer: {
    branding: brandingReducer,
    step: stepReducer
  },
});
