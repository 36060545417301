import React from 'react'

export const IconIngresaBoleto = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 239.5 239.5">
        <path d="M203.54,103.52l-4-14a2.6,2.6,0,0,0-3.37-1.63,9.78,9.78,0,1,1-5.41-18.79,2.6,2.6,0,0,0,2-3.18L189.23,53.8a14.17,14.17,0,0,0-17.54-9.71l-126,36.28A14.18,14.18,0,0,0,36,97.91c2,5.41,4.51,16.07,3.62,21.78A2.5,2.5,0,0,0,42,122.2a9.83,9.83,0,0,1,9,6.67,9.62,9.62,0,0,1-3.16,1.69,2.51,2.51,0,0,0-1.66,3.08L48,139.56A9.71,9.71,0,0,1,42,141.74a2.5,2.5,0,0,0-2.44,2.51v14.56A14.19,14.19,0,0,0,53.75,173H85.47a2.51,2.51,0,0,0,0-5H53.75a9.17,9.17,0,0,1-9.16-9.16V146.49a14.55,14.55,0,0,0,4.82-1.84l.86,3a14.18,14.18,0,0,0,17.54,9.7l116.05-33.4a14.77,14.77,0,0,0,10.2,22.65v12.22a9.17,9.17,0,0,1-9.17,9.16H97.48a2.51,2.51,0,0,0,0,5h87.41a14.19,14.19,0,0,0,14.18-14.18V144.25a2.61,2.61,0,0,0-2.8-2.5,9.78,9.78,0,0,1,0-19.56,2.59,2.59,0,0,0,2.8-2.49v-1.42a14.19,14.19,0,0,0,4.47-14.76ZM44.59,117.45v-6A9.85,9.85,0,0,1,54.3,124.1,14.92,14.92,0,0,0,44.59,117.45Zm80.76,2.77,9-2.59,4.08,14.17-9,2.59Zm72.59-8.34a9.45,9.45,0,0,1-5.48,4.37L155,127l-.28-1a2.5,2.5,0,0,0-4.81,1.39l.28,1-6.93,2-4.77-16.58a2.49,2.49,0,0,0-3.1-1.71l-13.81,4a2.53,2.53,0,0,0-1.5,1.2,2.49,2.49,0,0,0-.21,1.91l4.77,16.58L66.43,152.52a9.15,9.15,0,0,1-11.34-6.27l-3.41-11.83a14.79,14.79,0,0,0-8-27.91l-2.88-10a9.18,9.18,0,0,1,6.28-11.34l83.73-24.1.28,1a2.52,2.52,0,0,0,2.41,1.81,2.35,2.35,0,0,0,.69-.1,2.51,2.51,0,0,0,1.72-3.1l-.28-1,37.47-10.79a9.17,9.17,0,0,1,11.34,6.27l2.85,9.88a14.79,14.79,0,0,0,8.09,28.11l3.38,11.74a9.16,9.16,0,0,1-.79,7Z" />
        <path d="M141,78.61l-1.84-6.39a2.5,2.5,0,0,0-4.81,1.39L136.23,80a2.61,2.61,0,0,0,3.1,1.71,2.5,2.5,0,0,0,1.71-3.1Z" />
        <path d="M146.21,96.56l-1.84-6.39a2.51,2.51,0,0,0-4.82,1.39L141.39,98a2.62,2.62,0,0,0,3.1,1.71A2.51,2.51,0,0,0,146.21,96.56Z" />
        <path d="M149.54,108.12a2.51,2.51,0,0,0-4.82,1.39l1.84,6.39a2.62,2.62,0,0,0,3.1,1.71,2.51,2.51,0,0,0,1.72-3.1Z" />
        <path d="M176.26,68.54a2.49,2.49,0,0,0,.21-1.91l-2.8-9.74a2.5,2.5,0,0,0-3.1-1.71l-25.2,7.25a2.51,2.51,0,0,0-1.72,3.1l2.81,9.74c.51,1,1,2.11,3.1,1.72l25.2-7.26a2.48,2.48,0,0,0,1.5-1.19Zm-25.68,2.94-1.42-4.93,20.39-5.86L171,65.61Z" />
        <path d="M172.32,106.7l13.46-3.88A2.5,2.5,0,1,0,184.39,98l-13.46,3.87a2.51,2.51,0,0,0,.69,4.92,2.47,2.47,0,0,0,.7-.1Z" />
        <path d="M186.88,106.64l-13.47,3.88a2.5,2.5,0,0,0,.7,4.91,2.4,2.4,0,0,0,.69-.1l13.46-3.87a2.51,2.51,0,1,0-1.38-4.82Z" />
        <path d="M180,162.57a2.51,2.51,0,0,0,2.5-2.51v-9.7a2.5,2.5,0,0,0-2.5-2.5H152.37a2.5,2.5,0,0,0-2.5,2.5v9.7a2.51,2.51,0,0,0,2.5,2.51Zm-25.16-9.7h22.65v4.69H154.88Z" />
        <path d="M140.11,157.21a2.51,2.51,0,0,0,0-5H113.47a2.51,2.51,0,0,0,0,5Z" />
        <path d="M105.27,98.26a4.42,4.42,0,0,0,5.53,2.61,4.13,4.13,0,0,0,3.15-3.1l3.81-1a9.12,9.12,0,0,1-2.07,4.75,8,8,0,0,1-4.06,2.56,8.52,8.52,0,0,1-4.53-16.42,8.19,8.19,0,0,1,6.42.79,8.66,8.66,0,0,1,4.21,6.45ZM113.33,93A4.42,4.42,0,0,0,108,90.74a4.33,4.33,0,0,0-3.42,4.68Z" />
        <path d="M118.18,85.11l3.49-1,.56,2a4.59,4.59,0,0,1,3.6-3.68l1,3.72-.17,0c-2.59.7-3.59,2.28-2.86,5l2.41,8.82-3.69,1Z" />
        <path d="M99.2,89.67l-11,3a1.62,1.62,0,0,0-1.1,1.07l-.68,1.79a.16.16,0,0,0,.19.22l9.34-2.5a.66.66,0,0,0,.28-.16L99.39,90a.21.21,0,0,0,0-.29A.2.2,0,0,0,99.2,89.67Z" />
        <path d="M85.82,102.44c-1.41.39-4.4,3.85-4.4,3.85a.5.5,0,0,1-.29.12.46.46,0,0,1-.42-.24.43.43,0,0,1,0-.31l.81-3.93a1.72,1.72,0,0,0,0-.32.91.91,0,0,0,0-.23.74.74,0,0,0-.25-.39,2.11,2.11,0,0,0-.3-.21l-3.74-2.21a.43.43,0,0,1-.23-.51.43.43,0,0,1,.31-.26l4.15-1.13a2.41,2.41,0,0,0,1.51-1.24l.15-.34c.1-.25.83-2.22.91-2.5a13.48,13.48,0,0,0,1-4c0-.8-1.26-2.42-1.42-2.69a.61.61,0,0,0-.69-.32.77.77,0,0,0-.56.62c-.08.29-2.55,8-2.55,8a1.62,1.62,0,0,1-1.09,1L69,97.88c-.6.16-.83.54-.73.9h0a.94.94,0,0,0,0,.09,1.35,1.35,0,0,0,.5.52c2.49,1.59,7.34,4.33,7.57,4.49l.23.16a.79.79,0,0,1,.31.44,1.39,1.39,0,0,1,0,.59c-.4,2.16-1.57,7.67-1.61,7.92a1.07,1.07,0,0,0,0,.4v0a.46.46,0,0,0,.56.32l.06,0a1.05,1.05,0,0,0,.3-.17c.07,0,5.32-4.65,6.79-5.94.68-.59.92-.67,1.57-.53s6.08,1.48,7.53,1.85a2.23,2.23,0,0,0,1,.12h0a.59.59,0,0,0,.41-.73l0-.14s-1.48-4.25-1.55-4.44a.58.58,0,0,0-.06-.17C90.68,102.58,87.3,102,85.82,102.44Z" />
        <path d="M86.25,97.54l6.89-1.89a.4.4,0,0,1,.4.68L90.71,99a.45.45,0,0,1-.19.11l-4.32,1.18a.49.49,0,0,1-.27,0,.75.75,0,0,1-.58-.79,6.76,6.76,0,0,1,.5-1.57.38.38,0,0,1,.12-.16l.11-.09A.34.34,0,0,1,86.25,97.54Z" />
        <path d="M128.3,81.9a.8.8,0,1,0,.42,1.54.8.8,0,1,0-.42-1.54Zm.38,1.39a.65.65,0,0,1-.79-.45.64.64,0,0,1,.45-.78.62.62,0,0,1,.78.44A.63.63,0,0,1,128.68,83.29Z" />
        <path d="M128.79,82.39c0-.18-.18-.23-.38-.17l-.34.09.24.9.13,0-.1-.39.15,0,.34.32.15,0-.36-.32A.24.24,0,0,0,128.79,82.39Zm-.48.28-.08-.28.19,0c.09,0,.2,0,.23.08s-.07.17-.18.2Z" />
        <path d="M119.75,239.5A119.75,119.75,0,1,1,239.5,119.75,119.89,119.89,0,0,1,119.75,239.5Zm0-232.87A113.12,113.12,0,1,0,232.87,119.75,113.24,113.24,0,0,0,119.75,6.63Z" />
    </svg>
}

export const IconDatosFiscales = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 239.5 239.5">
        <path d="M119.75,239.5A119.75,119.75,0,1,1,239.5,119.75,119.89,119.89,0,0,1,119.75,239.5Zm0-232.87A113.12,113.12,0,1,0,232.87,119.75,113.24,113.24,0,0,0,119.75,6.63Z" />
        <path d="M179.78,127.75A1.77,1.77,0,0,1,178,126v-5.24a1.77,1.77,0,1,1,3.53,0V126A1.76,1.76,0,0,1,179.78,127.75Z" />
        <path d="M176.25,181.54h-113A5.29,5.29,0,0,1,58,176.25v-113A5.29,5.29,0,0,1,63.25,58h113a5.29,5.29,0,0,1,5.29,5.29v45.13a1.77,1.77,0,1,1-3.53,0V63.25a1.76,1.76,0,0,0-1.76-1.76h-113a1.76,1.76,0,0,0-1.76,1.76v113A1.76,1.76,0,0,0,63.25,178h113a1.76,1.76,0,0,0,1.76-1.76V155.84a1.77,1.77,0,1,1,3.53,0v20.41A5.29,5.29,0,0,1,176.25,181.54Z" />
        <path d="M179.78,145.25a1.77,1.77,0,0,1-1.77-1.77v-5.14a1.77,1.77,0,0,1,3.53,0v5.14A1.77,1.77,0,0,1,179.78,145.25Z" />
        <path d="M179.78,88H59.72a1.77,1.77,0,0,1,0-3.53H179.78a1.77,1.77,0,0,1,0,3.53Z" />
        <path d="M113.61,74.73h-43a1.77,1.77,0,1,1,0-3.53h43a1.77,1.77,0,0,1,0,3.53Z" />
        <path d="M167.09,74.73h-4.65a1.77,1.77,0,1,1,0-3.53h4.65a1.77,1.77,0,0,1,0,3.53Z" />
        <path d="M150,74.73h-4.65a1.77,1.77,0,1,1,0-3.53H150a1.77,1.77,0,1,1,0,3.53Z" />
        <path d="M133,74.73h-4.65a1.77,1.77,0,0,1,0-3.53H133a1.77,1.77,0,1,1,0,3.53Z" />
        <path d="M93.71,146.23a22.51,22.51,0,1,1,22.51-22.51A22.51,22.51,0,0,1,93.71,146.23Zm0-41.49a19,19,0,1,0,19,19A19,19,0,0,0,93.71,104.74Z" />
        <path d="M110.58,137.58a1.75,1.75,0,0,1-1.44-.74,18.93,18.93,0,0,0-30.86,0,1.76,1.76,0,0,1-2.88-2,22.46,22.46,0,0,1,36.62,0,1.76,1.76,0,0,1-1.44,2.78Z" />
        <path d="M93.71,128.88a9,9,0,1,1,9-9h0A9,9,0,0,1,93.71,128.88Zm0-14.5a5.48,5.48,0,1,0,5.48,5.48h0A5.48,5.48,0,0,0,93.71,114.38Z" />
        <path d="M169.19,109.16H123.28a1.77,1.77,0,0,1,0-3.53h45.91a1.77,1.77,0,0,1,0,3.53Z" />
        <path d="M169.19,127.4H126.81a1.77,1.77,0,0,1,0-3.53h42.38a1.77,1.77,0,0,1,0,3.53Z" />
        <path d="M169.19,145.65H119.75a1.77,1.77,0,0,1,0-3.54h49.44a1.77,1.77,0,0,1,0,3.54Z" />
        <path d="M169.19,163.89H73.85a1.77,1.77,0,1,1,0-3.53h95.34a1.77,1.77,0,0,1,0,3.53Z" />
    </svg>
}

export const IconGeneraFactura = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 241.61 241.61">
        <path d="M120.8,241.61A120.81,120.81,0,1,1,241.61,120.8,120.94,120.94,0,0,1,120.8,241.61Zm0-234.93A114.12,114.12,0,1,0,234.92,120.8,114.26,114.26,0,0,0,120.8,6.68Z" />
        <path d="M166.28,55.67H88.74a8.06,8.06,0,0,0-8,8v18a35.17,35.17,0,0,0,0,67.64v31a8.06,8.06,0,0,0,8,8h63.37a2.79,2.79,0,0,0,2-.83l19.39-19.38a2.87,2.87,0,0,0,.83-2V63.72A8.06,8.06,0,0,0,166.28,55.67ZM88.74,61.34h77.54a2.39,2.39,0,0,1,2.38,2.38v8.55H86.36V63.72A2.39,2.39,0,0,1,88.74,61.34ZM60.85,115.55a29.51,29.51,0,1,1,29.51,29.5A29.54,29.54,0,0,1,60.85,115.55Zm25.51,64.81V150.49a35.08,35.08,0,0,0,30.5-11.85h17.37a2.84,2.84,0,1,0,0-5.67H120.9a34.7,34.7,0,0,0,4.49-14.37h8.84a2.84,2.84,0,1,0,0-5.67h-8.8a34.77,34.77,0,0,0-4.29-14.37h13.09a2.84,2.84,0,1,0,0-5.67h-17A35.09,35.09,0,0,0,86.36,80.61V77.94h82.3v85.41H157.32a8.06,8.06,0,0,0-8,8v11.35H88.74A2.39,2.39,0,0,1,86.36,180.36Zm68.58-1.63v-7.34a2.38,2.38,0,0,1,2.38-2.37h7.33Z" />
        <path d="M145.37,98.56h9.41a2.84,2.84,0,1,0,0-5.67h-9.41a2.84,2.84,0,1,0,0,5.67Z" />
        <path d="M145.37,118.6h9.41a2.84,2.84,0,1,0,0-5.67h-9.41a2.84,2.84,0,1,0,0,5.67Z" />
        <path d="M145.37,138.64h9.41a2.84,2.84,0,1,0,0-5.67h-9.41a2.84,2.84,0,1,0,0,5.67Z" />
        <path d="M154.78,158.68a2.84,2.84,0,1,0,0-5.67H128.27a2.84,2.84,0,1,0,0,5.67Z" />
        <path d="M112.36,101.25l-3.45-3.46a4.95,4.95,0,0,0-7,0L83.79,115.93l-5-5a4.91,4.91,0,0,0-7,0l-3.46,3.46a4.94,4.94,0,0,0,0,7l12,12a4.89,4.89,0,0,0,3.48,1.44h0a4.9,4.9,0,0,0,3.49-1.44l25.08-25.09A4.92,4.92,0,0,0,112.36,101.25ZM83.79,128.78,72.88,117.86l2.41-2.41,6.5,6.49a2.83,2.83,0,0,0,4,0l19.62-19.62,2.41,2.42Z" />
    </svg>
}

export const IconPrint = ({ color }) => {
    return <svg 
        fill={color || "#005db4"} 
        viewBox="0 0 239.51 239.51"
        xmlns="http://www.w3.org/2000/svg" 
    >
        <g id="Capa_2" data-name="Capa 2">
            <g id="Capa_1-2" data-name="Capa 1">
                <path className="cls-1" d="M119.76,239.51A119.76,119.76,0,1,1,239.51,119.76,119.89,119.89,0,0,1,119.76,239.51Zm0-232.88A113.13,113.13,0,1,0,232.89,119.76,113.26,113.26,0,0,0,119.76,6.63Z"/>
                <path className="cls-1" d="M146.54,78.1a2.19,2.19,0,0,0,2.2-2.19h0V63a2.2,2.2,0,1,0-4.39,0V75.91a2.19,2.19,0,0,0,2.19,2.19Z"/>
                <path className="cls-1" d="M184.43,95.39H171.94V64.81a2.19,2.19,0,0,0-2.19-2.19H156.27V50.37a2.19,2.19,0,0,0-2.19-2.19H85.44a2.19,2.19,0,0,0-2.2,2.19V62.62H69.76a2.19,2.19,0,0,0-2.19,2.19V95.39H55.08a9.75,9.75,0,0,0-9.73,9.73V165a9.75,9.75,0,0,0,9.73,9.73H83.23v20a2.2,2.2,0,0,0,2.2,2.2h68.65a2.2,2.2,0,0,0,2.2-2.2v-20h28.15a9.75,9.75,0,0,0,9.73-9.73V105.12A9.75,9.75,0,0,0,184.43,95.39ZM167.55,67V95.39H156.27V67ZM87.63,52.57h64.25V95.39H87.63ZM72,67H83.24V95.39H72Zm0,103.36V148H83.23v22.36Zm15.67,22.22V148h64.25v44.58Zm68.65-22.22V148h11.27v22.36ZM189.76,165a5.33,5.33,0,0,1-5.33,5.33H171.94V145.81a2.2,2.2,0,0,0-2.19-2.2h-100a2.2,2.2,0,0,0-2.19,2.2v24.56H55.08A5.33,5.33,0,0,1,49.75,165V105.12a5.33,5.33,0,0,1,5.33-5.33H184.43a5.33,5.33,0,0,1,5.33,5.33Z"/>
                <path className="cls-1" d="M90,110.46H58.85a2.2,2.2,0,1,0,0,4.39H90a2.2,2.2,0,0,0,0-4.39Z"/>
                <path className="cls-1" d="M138.59,154.29H100.92a2.2,2.2,0,1,0,0,4.39h37.67a2.2,2.2,0,1,0,0-4.39Z"/>
                <path className="cls-1" d="M138.59,165.59H100.92a2.2,2.2,0,1,0,0,4.39h37.67a2.2,2.2,0,1,0,0-4.39Z"/>
                <path className="cls-1" d="M138.59,176.89H100.92a2.2,2.2,0,1,0,0,4.4h37.67a2.2,2.2,0,0,0,0-4.4Z"/>
                <path className="cls-1" d="M176.89,106.69a6,6,0,1,0,6,6h0A6,6,0,0,0,176.89,106.69Zm0,7.53a1.57,1.57,0,1,1,1.57-1.57A1.57,1.57,0,0,1,176.89,114.22Z"/>
                <path className="cls-1" d="M162,106.69a6,6,0,1,0,6,6h0A6,6,0,0,0,162,106.69Zm0,7.53a1.57,1.57,0,1,1,1.57-1.57A1.57,1.57,0,0,1,162,114.22Z"/>
                <path className="cls-1" d="M147.07,106.69a6,6,0,1,0,6,6A6,6,0,0,0,147.07,106.69Zm0,7.53a1.57,1.57,0,1,1,1.57-1.57h0A1.58,1.58,0,0,1,147.07,114.22Z"/>
            </g>
        </g>
    </svg>
}

export const IconDescargar = ({ color }) => {
    return <svg 
        fill={color || "#005db4"} 
        viewBox="0 0 239.51 239.51"
        xmlns="http://www.w3.org/2000/svg" 
    >
        <g id="Capa_2" data-name="Capa 2">
            <g id="Capa_1-2" data-name="Capa 1">
                <path className="cls-1" d="M119.76,239.51A119.76,119.76,0,1,1,239.51,119.76,119.89,119.89,0,0,1,119.76,239.51Zm0-232.88A113.13,113.13,0,1,0,232.89,119.76,113.26,113.26,0,0,0,119.76,6.63Z"/>
                <g id="surface1">
                    <path className="cls-1" d="M143.65,47.84a3.56,3.56,0,0,0-2.54-1.13h-57a19.28,19.28,0,0,0-19.2,19.16V173.64a19.28,19.28,0,0,0,19.2,19.16h77.35a19.29,19.29,0,0,0,19.2-19.16V88.07a3.79,3.79,0,0,0-1-2.47Zm1,11.33,24.1,25.3H153.11a8.41,8.41,0,0,1-8.44-8.4Zm16.8,126.57H84.12A12.22,12.22,0,0,1,72,173.64V65.87a12.22,12.22,0,0,1,12.14-12.1h53.49v22.3a15.45,15.45,0,0,0,15.5,15.46h20.5v82.11A12.2,12.2,0,0,1,161.47,185.74Z"/>
                    <path className="cls-1" d="M151.84,161.4H93.75a3.53,3.53,0,1,0,0,7h58.12a3.53,3.53,0,1,0,0-7Z"/>
                    <path className="cls-1" d="M120.22,148.73a3.5,3.5,0,0,0,5.15,0l20.68-22.2a3.52,3.52,0,0,0-5.15-4.8l-14.58,15.63V98.83a3.53,3.53,0,1,0-7,0v38.53l-14.54-15.63a3.52,3.52,0,0,0-5.15,4.8Z"/>
                </g>
            </g>
        </g>
    </svg>
}