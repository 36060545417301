export const ER_IDENTIFIER = 'AMP521016875';

export const constants = {
  ER_IDENTIFIER: 'AMP521016875',
  EB_KEY: 'eb',
  ENVIA_KEY: 'envia',
  SENDERO_KEY: 'sendero',
  CLAVE_AUTOBUS: 'AUTOBUS',
  CLAVE_GUIA: 'GUIA',
  CLAVE_CONSUMO: 'CONSUMO',
}

export const ebBrandData = {
  mail: "atencion.cliente@estrellablanca.com.mx",
  phone: "55 5118 4955",
  // whatsapp: "(222) 5260 000",
  // whatsappLink: "2225260000",
  facebookUrl: "https://www.facebook.com/EstrellaRojaPuebla/",
  twitterUrl: "https://twitter.com/estrellarojapue",
  instagramUrl: "https://www.instagram.com/estrellaroja_puebla",
  youtubeUrl: "https://www.youtube.com/channel/UCQWshfti8IfJjMGKLR6zRsg",
  linkedInUrl: "https://www.linkedin.com/company/estrella-roja-puebla/",
  origenesUrl: "https://www.estrellaroja.com.mx/destinos",
  politicasPrivacidadUrl: "https://www.estrellaroja.com.mx/privacidad",
  politicasViajeUrl: "https://www.estrellaroja.com.mx/politicas"
}

export const enviaBrandData = {
  mail: "atencion.cliente@estrellablancaenvia.com.mx",
  phone: "55 5118 4955",
  // whatsapp: "(222) 5260 000",
  // whatsappLink: "2225260000",
  facebookUrl: "https://www.facebook.com/EstrellaRojaPuebla/",
  twitterUrl: "https://twitter.com/estrellarojapue",
  instagramUrl: "https://www.instagram.com/estrellaroja_puebla",
  youtubeUrl: "https://www.youtube.com/channel/UCQWshfti8IfJjMGKLR6zRsg",
  linkedInUrl: "https://www.linkedin.com/company/estrella-roja-puebla/",
  origenesUrl: "https://www.estrellaroja.com.mx/destinos",
  politicasPrivacidadUrl: "https://www.estrellaroja.com.mx/privacidad",
  politicasViajeUrl: "https://www.estrellaroja.com.mx/politicas"
}

export const senderoBrandData = {
  mail: "atencion.cliente@estrellablancasendero.com.mx",
  phone: "55 5118 4955",
  // whatsapp: "(222) 5260 000",
  // whatsappLink: "2225260000",
  facebookUrl: "https://www.facebook.com/EstrellaRojaPuebla/",
  twitterUrl: "https://twitter.com/estrellarojapue",
  instagramUrl: "https://www.instagram.com/estrellaroja_puebla",
  youtubeUrl: "https://www.youtube.com/channel/UCQWshfti8IfJjMGKLR6zRsg",
  linkedInUrl: "https://www.linkedin.com/company/estrella-roja-puebla/",
  origenesUrl: "https://www.estrellaroja.com.mx/destinos",
  politicasPrivacidadUrl: "https://www.estrellaroja.com.mx/privacidad",
  politicasViajeUrl: "https://www.estrellaroja.com.mx/politicas"
}