import React, { useCallback, useState } from "react";
import "./styles/slider.css";

function determineClasses(indexes, cardIndex) {
  if (indexes.currentIndex === cardIndex) {
    return "activa";
  } else if (indexes.nextIndex === cardIndex) {
    return "siguiente";
  } else if (indexes.previousIndex === cardIndex) {
    return "anterior";
  }
  return "inactiva";
}

const CardCarousel = (props) => {
  /**
   * Brand/Theming 
   */
  const [indexes, setIndexes] = useState({
    previousIndex: 0,
    currentIndex: 0,
    nextIndex: 1
  });

  const handleCardTransition = useCallback(() => {
    const element = document.getElementById('efectoSobraActiva');
    if (element) {
      element.classList.remove('efectoSobraActiva');
      element.classList.add('efectoSobraActiva');
    }
    setTimeout(() => {
      element.classList.remove('efectoSobraActiva');
    }, 1000);
    if (indexes.currentIndex >= props.data.length - 1) {
      setIndexes({
        previousIndex: props.data.length - 1,
        currentIndex: 0,
        nextIndex: 1
      });
    } else {
      setIndexes(prevState => ({
        previousIndex: prevState.currentIndex,
        currentIndex: prevState.currentIndex + 1,
        nextIndex:
          prevState.currentIndex + 2 === props.data.length
            ? 0
            : prevState.currentIndex + 2
      }));
    }
  }, [indexes.currentIndex]);

//   useEffect(() => {
//     const transitionInterval = setInterval(() => {
//       handleCardTransition();
//     }, 1000);

//     return () => clearInterval(transitionInterval);
//   }, [handleCardTransition, indexes]);
  return (
    <>
      {
      // brand === constants.EB_KEY && 
      props.data && props.data.length > 1 &&
        <div style={{ display: 'flex', justifyContent: 'center' }} >
          <button className="change-factura" onClick={() => handleCardTransition()}>
            <img src={`${process.env.PUBLIC_URL}/assets/exchange.svg`} alt="change" />
          </button>
        </div>
      }
      <div className="card-carousel1">
      {[...props.data.slice(0, props.data.length-1)].map((e, index) => {
        return (
        <div key={index} className={`efectoSobra datos-generales ${index !== 0 ? 'noEfectoSobraActiva' : ''}`} id={`${index === 0 ? 'efectoSobraActiva' : ''}`}
        style={{
          padding: `0 ${(props.data.length - 1 - index)}rem`, 
          marginTop: index !== 0 ? '-3rem' : '' }} >
          <div className="preview"><br/><br/></div>
        </div>
        )
      })
      }
      </div>
      <ul className="card-carousel1">
          {props.data.map((e, index) => {
            return (
              <li key={index} 
              style={{ marginTop: props.data.length === 1 ? '1.5rem' : '' }} 
              className={`card1 ${determineClasses(indexes, index)}`} >
                {e}
              </li>
          )})
          }
      </ul>
    </>
  );
};

export default CardCarousel;