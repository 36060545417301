import React from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import './landingPage.css'
import Facturacion from './Facturacion';
export const LandingPage = ({ setIsRefresh }) => {
    console.log(process.env)
    return <>
        <div className='main-landing'>
            <main>
                <div className="container mb-5">
                    {/* <header > */}
                    <div>
                        <img
                            width="270px"
                            height="100px"
                            alt="logo"
                            src={`${process.env.PUBLIC_URL}/landing-page/assets/LogoEB-blanco.png`}
                        />
                    </div>
                    {/* </header> */}
                    <div className="row">
                        <div className="col-sm-8 col-md-6 mt-3">
                            <span className="encabezado1-bold">¡Genera tu factura en </span>
                            <br />
                            <span className="encabezado1-normal">3 sencillos pasos!</span>
                        </div>
                        <div className="col-sm-4 col-md-6 d-flex justify-content-end">
                            <img
                                width="270px"
                                height="300px"
                                src={`${process.env.PUBLIC_URL}/landing-page/assets/ceclular.png`}
                            />
                        </div>
                    </div>
                    <div className="mt-5 d-flex justify-content-center container-sub p-4">
                        <span className="encabezado2-normal color-white">¿Qué desea facturar?</span>
                    </div>
                    <div className="mt-3 row gx-3">
                        <CardLanding 
                            titulo="Transporte"
                            src={`${process.env.PUBLIC_URL}/landing-page/assets/EB.jpg`}
                        />
                        <div className="col-xs-12 col-sm-6 col-md-6">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12">
                                    <Link onClick={() => setIsRefresh(true)} to="/paqueteria">
                                        <CardLandingItem 
                                            titulo="Paquetería"
                                            src={`${process.env.PUBLIC_URL}/landing-page/assets/ENVIA.jpg`}
                                        />
                                    </Link>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 d-flex justify-content-center">
                                    <span className="encabezado2-normal color-white">Paquetería</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                </div>
            </main>
            <Footer />
        </div>
    </>
}

const CardLanding = ({ titulo, src, }) => {
    const onClick = () => {
        window.open(process.env.REACT_APP_DEV_URI_URL_EB,  '_blank');
    }
    return <div className="col-xs-12 col-sm-6 col-md-6">
        <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12">
                <span
                    onClick={onClick}
                    style={{ cursor: "pointer" }}
                >
                    <CardLandingItem 
                        titulo={titulo}
                        src={src}
                    />
                </span>
            </div>
        </div>
        <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 d-flex justify-content-center">
                <span className="encabezado2-normal color-white">{titulo}</span>
            </div>
        </div>
    </div>
}

const CardLandingItem = ({ titulo, src }) => {
    return <div className="container-img">
        <img
            alt={titulo}
            className="image"
            src={src}
        />
        <div className="middle">
            <div className="text-container-img">{titulo}</div>
        </div>
    </div>
}

const Footer = () => {
    return <div className="footer-landing mt-auto mt-5">
        <div className="container">
            <div className="row gx-3">
                <div className="col-xs-6 col-sm-6 col-md-6 mt-3">
                    <span>
                        © Copyright Estrella Blanca
                    </span>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-6 mt-3">
                    <div className="row gx-3 d-flex">
                        <FooterRedSocial 
                            className="fa-facebook-f"
                            href="https://www.facebook.com/GrupoEstrellaBlancaOficial/"
                        />
                        <FooterRedSocial 
                            className="fa-instagram"
                            href="https://www.instagram.com/grupoestrellablanca_oficial/"
                        />
                        <FooterRedSocial 
                            className="fa-twitter"
                            href="https://x.com/GEB_uniendo_mex"
                        />
                        <FooterRedSocial 
                            className="fa-youtube"
                            href="https://www.youtube.com/channel/UC9en6oO5I7Av_Wk2ik5iU9g"
                        />
                        <FooterRedSocial 
                            className="fa-linkedin-in"
                            href="https://www.linkedin.com/company/grupo-estrella-blanca/"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
}

const FooterRedSocial = ({ className, href }) => {
    return <div className="circulo-rs">
        <a target="_blank" rel="noopener noreferrer" href={href}>
            <i className={`fa-brands ${className}`} />
        </a>
    </div>
}