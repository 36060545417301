const url = process.env.REACT_APP_API_URL;
const headers = new Headers();
headers.append('Content-Type', 'application/json');
headers.append('Accept', 'application/json');
headers.append('Origin','http://localhost:3000');

const aplicacion = "backend";

export const getTicket = (body, tipo) => {
  const params = new URLSearchParams(body);
  const req = new Request(`${url}/${aplicacion}/Dataservices/Autofactura/Catalogos/obtenerTicket/GEB?tipo=${tipo}&${params}`)
  return fetch(req)
}

export const getUsoCfdi = (rfc = '', cveRegimenFiscal = '') => {
  const req = new Request(`${url}/${aplicacion}/Dataservices/Autofactura/Catalogos/listarUsoCfdi?rfc=${rfc}&regimenFiscal=${cveRegimenFiscal}`)
  return fetch(req)
}
export const getRegimenFiscal = (rfc) => {
  const req = new Request(`${url}/${aplicacion}/Dataservices/Autofactura/Catalogos/listarRegimenFiscal/${rfc}`)
  return fetch(req)
}

export const getFactura = (body) => {
  const postConfig = {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(body)
  }
  return fetch(`${url}/${aplicacion}/Dataservices/Autofactura/Operacion/generarCfdi`, postConfig);
  // return { data: preFactura}
}

export const getCfdi = (body) => {
  const req = new Request(`${url}/${aplicacion}/Dataservices/Autofactura/Operacion/listarCfdi/filtro?rfc=${body.rfc}&folio=${body.folio}&fecha=${body.fecha}&claveTicket=${body.claveTicket}`)
  return fetch(req)
}

export const getFile = (fileUrl) => {
  const req = new Request(`${url}/${aplicacion}/Dataservices/Autofactura/Operacion/obtenerRutaS3?keyUrl=${fileUrl}`)
  return fetch(req)
}

export const getReceptor = (receptor) => {
  const req = new Request(`${url}/${aplicacion}/Dataservices/Autofactura/Catalogos/obtenerReceptor/${receptor}`)
  return fetch(req)
}

export const getCurrentPublication = (profile) => {
  const req = new Request(`${url}/${aplicacion}/Dataservices/configuracion/obtenerPublicacionActual/GEB/${profile}`)
  return fetch(req)
}

export const getRedContacto= (profile) => {
  const req = new Request(`${url}/${aplicacion}/Dataservices/configuracion/obtenerRedContacto/GEB/${profile}`)
  return fetch(req)
}